module.exports = [
  {
    riderName: 'Scott McCreadie',
    position: 'Chapter Lead',
    ridingFor: 'Alan McMurray',
    eow: '04/02/2006',
    image: '/images/profile/scott_mccreadie',
    sponsorName: 'Scott',
    justGiving: 'https://www.justgiving.com/page/scott-mccreadie-1734106372555',
    bio: ``,
  },
  {
    riderName: 'Ewan Fraser',
    position: 'Rider',
    ridingFor: '',
    eow: '',
    image: '/images/profile/ewan_fraser',
    sponsorName: 'Ewan',
    justGiving: 'https://www.justgiving.com/page/ewan-fraser-1730154187798',
    bio: ``,
  },
  {
    riderName: 'Jiles Alcock',
    position: 'Rider / Survivor',
    ridingFor: 'John Alcock',
    eow: '07/10/2017',
    image: '/images/profile/jiles',
    sponsorName: 'Jiles',
    justGiving: 'https://www.justgiving.com/page/jiles-alcock-1730205320600',
    bio: ``,
  },
  {
    riderName: 'Mark McFern',
    position: 'Rider',
    ridingFor: '',
    eow: '',
    image: '/images/profile/mark_mcfern',
    sponsorName: 'Mark',
    justGiving: 'https://www.justgiving.com/page/mark-mcfern-1730313639573',
    bio: ``,
  },
  {
    riderName: 'Mike Quinn',
    position: 'Rider',
    ridingFor: '',
    eow: '',
    image: '/images/profile/mike',
    sponsorName: 'Mike',
    justGiving: 'https://www.justgiving.com/page/mike-quinn-1730462849634',
    bio: ``,
  },
  {
    riderName: "John O'Hara",
    position: 'Rider',
    ridingFor: '',
    eow: '',
    image: '/images/profile/john_ohara',
    sponsorName: 'John',
    justGiving: 'https://www.justgiving.com/page/john-ohara-1730461959075',
    bio: ``,
  },
  {
    riderName: 'Alan Joyce',
    position: 'Rider',
    ridingFor: 'Neil McCover',
    eow: '08/10/2010',
    image: '/images/profile/alan_joyce',
    sponsorName: 'Alan',
    justGiving: 'https://www.justgiving.com/page/alan-joyce-2025',
    bio: ``,
  },
  {
    riderName: 'Colin Cravens',
    position: 'Rider',
    ridingFor: '',
    eow: '',
    image: '/images/profile/colin_cravens',
    sponsorName: 'Colin',
    justGiving: 'https://www.justgiving.com/page/colin-cravens-1732487055009',
    bio: ``,
  },
  {
    riderName: 'Iain McKie',
    position: 'Rider',
    ridingFor: '',
    eow: '',
    image: '/images/profile/iain_mckie',
    sponsorName: 'Iain',
    justGiving:
      'https://www.justgiving.com/page/julia-and-iain-mckie-1731929396276',
    bio: ``,
  },
  {
    riderName: 'Julia McKie',
    position: 'Rider',
    ridingFor: '',
    eow: '',
    image: '/images/profile/jules_mckie',
    sponsorName: 'Julia',
    justGiving:
      'https://www.justgiving.com/page/julia-and-iain-mckie-1731929396276',
    bio: ``,
  },
  {
    riderName: 'Gavin Kelly',
    position: 'Rider',
    ridingFor: '',
    eow: '',
    image: '/images/profile/gavin_kelly',
    sponsorName: 'Gavin',
    justGiving: 'https://www.justgiving.com/page/gavin-kelly-1732534195074',
    bio: ``,
  },
  {
    riderName: 'Alix Brodie',
    position: 'Rider / Survivor',
    ridingFor: '',
    eow: '',
    image: '/images/profile/alix_brodie',
    sponsorName: 'Alix',
    justGiving:
      'https://www.justgiving.com/page/alix-lewis-brodie-1732824423823',
    bio: ``,
  },
  {
    riderName: 'Lewis Brodie',
    position: 'Rider / Survivor',
    ridingFor: 'Kenneth Brodie',
    eow: '08/01/1997',
    image: '/images/profile/lewis',
    sponsorName: 'Lewis',
    justGiving:
      'https://www.justgiving.com/page/alix-lewis-brodie-1732824423823',
    bio: ``,
  },
  {
    riderName: 'Martin Burnett',
    position: 'Rider',
    ridingFor: '',
    eow: '',
    image: '/images/profile/martin',
    sponsorName: 'Martin',
    justGiving: 'https://www.justgiving.com/page/martin-burnett-1732816550953',
    bio: ``,
  },
  {
    riderName: 'Ian Dawes',
    position: 'Rider',
    ridingFor: 'John Hewitt',
    eow: '',
    image: '/images/profile/ian',
    sponsorName: 'Ian',
    justGiving: 'https://www.justgiving.com/page/ian-dawes-1732788268048',
    bio: ``,
  },
  {
    riderName: 'Ally Johnson',
    position: 'Rider',
    ridingFor: '',
    eow: '',
    image: '',
    sponsorName: 'Ally',
    justGiving: 'https://www.justgiving.com/page/alister-johnson-1732831442391',
    bio: ``,
  },
  {
    riderName: 'Adam Peppard',
    position: 'Rider',
    ridingFor: '',
    eow: '',
    image: '',
    sponsorName: 'Adam',
    justGiving: 'https://www.justgiving.com/page/adam-peppard-1732906927503',
    bio: ``,
  },
  {
    riderName: 'Glenn Bennett',
    position: 'Rider',
    ridingFor: '',
    eow: '',
    image: '',
    sponsorName: 'Glenn',
    justGiving: 'https://www.justgiving.com/page/glenn-bennett-1733124242359',
    bio: ``,
  },
  {
    riderName: 'Louise Harding',
    position: 'Rider',
    ridingFor: '',
    eow: '',
    image: '',
    sponsorName: 'Louise',
    justGiving: 'https://www.justgiving.com/page/louise-harding-1733970958903',
    bio: ``,
  },
  {
    riderName: 'Kate Jackson',
    position: 'Rider',
    ridingFor: 'Steve Cully',
    eow: '09/09/2011',
    image: '',
    sponsorName: 'Kate',
    justGiving: 'https://www.justgiving.com/page/kate-jackson-1734088940272',
    bio: ``,
  },
  {
    riderName: 'Mark Sutherland',
    position: 'Rider',
    ridingFor: '',
    eow: '',
    image: '',
    sponsorName: 'Mark',
    justGiving: 'https://www.justgiving.com/page/mark-sutherland-1735481280874',
    bio: ``,
  },
  {
    riderName: 'Stuart McMillan',
    position: 'Rider',
    ridingFor: '',
    eow: '',
    image: '',
    sponsorName: 'Stuart',
    justGiving: 'https://www.justgiving.com/page/stuart-mcmillan-1',
    bio: ``,
  },
  {
    riderName: 'Lynne Grassick',
    position: 'Rider',
    ridingFor: 'Steve Cully',
    eow: '09/09/2011',
    image: '/images/profile/lynne_grassick',
    sponsorName: 'Lynne',
    justGiving: 'https://www.justgiving.com/page/lynne-grassick-1739556890194',
    bio: ``,
  },
  {
    riderName: 'Khaldoun Kabbani',
    position: 'Rider',
    ridingFor: '',
    eow: '',
    image: '',
    sponsorName: 'Khaldoun',
    justGiving: 'https://www.justgiving.com/page/khaldoun-kabbani',
    bio: ``,
  },
  {
    riderName: 'Safdar Sadiq',
    position: 'Rider',
    ridingFor: '',
    eow: '',
    image: '/images/profile/safdar_sadiq',
    sponsorName: 'Safdar',
    justGiving: 'https://www.justgiving.com/page/safdar-sadiq-13',
    bio: ``,
  },
  {
    riderName: 'Gillian Oliver',
    position: 'Rider',
    ridingFor: '',
    eow: '',
    image: '',
    sponsorName: 'Gillian',
    justGiving: 'https://www.justgiving.com/page/gillian-oliver-1',
    bio: ``,
  },
  {
    riderName: 'Shaik Sharif',
    position: 'Rider',
    ridingFor: '',
    eow: '',
    image: '',
    sponsorName: 'Shaik',
    justGiving: 'https://www.justgiving.com/page/shaik-sharif-spma',
    bio: ``,
  },
  {
    riderName: 'Jane Dunbar',
    position: 'Rider',
    ridingFor: '',
    eow: '',
    image: '',
    sponsorName: 'Jane',
    justGiving: 'https://www.justgiving.com/page/jane-dunbar-2',
    bio: ``,
  },
  {
    riderName: 'Shannon Dunbar',
    position: 'Rider',
    ridingFor: '',
    eow: '',
    image: '',
    sponsorName: 'Shannon',
    justGiving: 'https://www.justgiving.com/page/shannon-dunbar-4',
    bio: ``,
  },
  {
    riderName: 'Matt Le Core',
    position: 'Rider',
    ridingFor: '',
    eow: '',
    image: '/images/profile/matt_lecore',
    sponsorName: 'Matt',
    justGiving: 'https://www.justgiving.com/page/mmatt-lc-1',
    bio: ``,
  },
  {
    riderName: 'Gerry Corrigan',
    position: 'Rider',
    ridingFor: '',
    eow: '',
    image: '',
    sponsorName: 'Gerry',
    justGiving: 'https://www.justgiving.com/page/gerry-corrigan-1',
    bio: ``,
  },
  {
    riderName: 'Jim Henderson',
    position: 'Rider',
    ridingFor: '',
    eow: '',
    image: '',
    sponsorName: 'Jim',
    justGiving: 'https://www.justgiving.com/page/',
    bio: ``,
  },
  {
    riderName: 'Adam Watret',
    position: 'Rider',
    ridingFor: '',
    eow: '',
    image: '',
    sponsorName: 'Adam',
    justGiving: 'https://www.justgiving.com/page/',
    bio: ``,
  },
];
